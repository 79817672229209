<template>
  <div class="supplierManagement" v-loading="loading">
    <div class="globle_border content">
      <header>
        <el-date-picker v-model="timeValue" type="date" placeholder="请选择结算日期" @change="onRevisionDate"></el-date-picker>
        <el-select v-model="payStatusVal" placeholder="选择缴费状态" clearable @change="onCurrentPage">
          <el-option v-for="item in payStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <!-- <el-button type="primary" @click="changeAll">批量缴纳</el-button> -->
      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData" style="width: 100%" ref="multipleTable"
          @selection-change="handleSelectionChange">
          <!-- <el-table-column type="selection" width="110" :selectable="row => row.status == 0"></el-table-column> -->
          <el-table-column label="序号" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
              <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <el-table-column prop="feeCode" label="缴费单号" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="createdTime" label="结算时间" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="" label="结算周期" show-overflow-tooltip min-width="200" align="center">
            <template slot-scope="scope">
              {{ `${scope.row.startDate}-${scope.row.endDate}` }}
            </template>
          </el-table-column>
          <el-table-column prop="settlementAmount" label="结算金额(元)" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="feeAmount" label="服务费金额(元)" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column prop="payTime" label="缴费时间" show-overflow-tooltip min-width="160" align="center">
          </el-table-column>
          <el-table-column prop="" label="缴费状态" show-overflow-tooltip min-width="120" align="center">
            <template slot-scope="scope">
              {{ scope.row.status == 0 ? "未缴费" : scope.row.status == 1 ? "已缴费" : "已完成" }}
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" header-align="center" align="center" min-width="160" fixed="right">
            <template slot-scope="scope">
              <el-button v-if="scope.row.status == 0" type="primary" size="small"
                @click="handlePay(scope.row)">去缴费</el-button>
              <el-button type="primary" size="small" plain @click="handleCommand(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
        layout="prev, pager, next, jumper, sizes, total" :total="totalCount"></el-pagination>
    </div>
    <!-- 手机验证码 -->
    <child :showFlag="showFlag" @closeChildDialog="closeChildDialog" v-if="flag"></child>
  </div>
</template>

<script>
import child from "./detail/payDetPage.vue"
export default {
  components: {
    child,
  },
  data() {
    return {
      loading: false,
      showFlag: false,
      flag: false,
      // // 表格数据
      tableData: [],
      payStatus: [
        {
          value: "0",
          label: "未缴费",
        },
        {
          value: "1",
          label: "已缴费",
        },
        {
          value: "2",
          label: "已完成",
        },
      ],
      // 分页器
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50],
      totalCount: 0, // 总记录数据
      timeValue: "", //选择日期
      payStatusVal: "", //缴费状态
      multipleSelection: [], //全选当页数据
    }
  },
  created() {
    this.getRequest()
  },
  methods: {
    // 请求列表
    getRequest() {
      this.loading = true
      this.$axios
        .get(this.$api.techInfoList, {
          params: {
            bizId: this.$store.state.userInfo.supplierId, //供应商id
            bizType: 2, //类型：1商超；2供应商
            startDate: this.timeValue || null, //选择时间
            endDate: this.timeValue || null, //选择时间
            status: this.payStatusVal || null, //缴费状态 状态：0待付款；1已付款；2已确认（完成）
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          const { code, result } = res.data
          if (code == 100) {
            this.totalCount = result.totalCount
            this.tableData = result.list
            this.loading = false
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    // 日期搜索
    onRevisionDate(date) {
      if (date == null) {
        this.timeValue = ""
      } else {
        let formattedDate = new Date(new Date(date).getTime() + 8 * 60 * 60 * 1000).toISOString().split("T")[0]
        this.timeValue = formattedDate
      }
      this.onCurrentPage()
    },
    // 点击跳转查看
    handleCommand(row) {
      this.$router.push({ name: "feeDetails", query: { id: row.id } })
    },
    // 全选选中弹窗的数据
    handleSelectionChange(val) {
      this.multipleSelection = val
      console.log("选中弹窗", val)
    },
    // 全选按钮
    changeAll() {
      console.log("选中弹窗数据", this.multipleSelection)
      if (this.multipleSelection.length > 0) {
        this.$confirm("一键缴纳当页全选数据的技术服务费, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          center: true,
          type: "warning",
        })
          .then(() => {
            // 调取后端接口
            this.$message({
              message: "恭喜你，缴纳成功！",
              type: "success",
            })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
      } else {
        this.$message.error("点击批量按钮前请选择缴纳数据!")
      }
    },
    // 缴费按钮
    // 检查用户当前支付平台绑定状态
    handlePay(row) {
      console.log("点击缴费按钮")
      this.flag = true
      //  缓存第三方支付的获取验证码参数和支付参数
      let query = {
        sourceId: row.id, // 对应平台的主键id
        fee: row.feeAmount, // 缴费金额
        supplierId: this.$store.state.userInfo.supplierId, //供应商id
        // sourceType: row.eligibleType, // 支付来源：1平台应标资格；2独立标应标资格；3保证金
        sourceType: 4, // 支付来源：1平台应标资格；2独立标应标资格；3保证金,4技术服务费
      }
      this.$store.commit("setPayQuery", query)
      this.$axios
        .post(this.$api.select_token, {
          phoneNum: this.$store.state.userInfo.userAccount, // 手机号
          supplierId: this.$store.state.userInfo.supplierId, // 供应商id
        })
        .then(res => {
          const { code, result } = res.data
          if (code == 100) {
            // result为空时该供应商没有绑定第三方支付平台
            if (result == null) {
              this.showFlag = true
            } else {
              this.$router.push({ path: "/technicalService/detail/twoCodePage" })
            }
          }
        })
      console.log(this.$api.select_token)
    },
    //  接受子组件调用的关闭弹出框方法
    closeChildDialog() {
      this.showFlag = false
    },
    // 分页器
    onCurrentPage() {
      this.tableData = []
      this.currentPage = 1
      this.getRequest()
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.onCurrentPage()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val
      this.tableData = []
      this.getRequest()
    },
  },
}
</script>

<style scoped lang="scss">
.supplierManagement {
  .content {
    .ellipsis-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1.5;
    }

    header {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .el-input,
      .el-select {
        width: 180px;
        margin-left: 10px;
        margin-bottom: 20px;
      }

      .el-button {
        margin-left: 10px;
        margin-bottom: 20px;
      }

      .el-date-editor {
        margin-left: 10px;
        width: 180px;
      }

      /deep/ .el-date-editor {
        background-color: #f2f2f2;
        border: none;
        border-radius: 10px;
      }

      /deep/.el-range-input {
        background-color: #f2f2f2;
      }

    }
  }

  .tableBox {
    overflow: auto;

    :deep .el-table__header-wrapper .el-checkbox__input::after {
      content: "当前页全选";
      position: absolute;
      margin-left: 10px;
      color: rgba(3, 16, 14, 0.9);
      font-weight: 600;
    }

    :deep .el-table--group::after,
    .el-table--border::after,
    .el-table::before {
      content: "";
      position: absolute;
      background-color: #ebeef5;
      opacity: 0;
    }

    :deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 6px;
      /*滚动条宽度*/
      height: 4px;
      /*滚动条高度*/
    }
  }

  // 操作下拉框内容
  .el-dropdown-link {
    cursor: pointer;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }
}
</style>
