<!-- 手机验证码-->
<template>
  <div v-loading="loading">
    <el-dialog title="验证手机号" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form label-width="120px" :model="form">
        <el-row class="captcha_row">
          <el-col :span="24">
            <el-form-item label="手机号码">
              <el-input v-model="form.phoneNumber" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="短信验证码">
              <el-input v-model="form.msgcode" placeholder="输入验证码"></el-input>
              <el-button type="primary" class="send" @click="getCaptcha" v-if="!sendMsgDisabled">发送</el-button>
              <el-button type="info" class="send" v-else>{{ countdown }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="verifyCaptcha" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  props: {
    // 接受父级组件的显示标记，用于被watch
    showFlag: {
      type: Boolean
    },
  },
  data() {
    return {
      basicInfo: {},//详情  价格在里面
      countdown: 30, // 倒计时时间  
      sendMsgDisabled: false, // 按钮是否禁用  
      timer: null, // 定时器  
      loading: false,
      submitLoding: false,
      supplierId: '',
      form: {
        phoneNumber: '',
        msgcode: '',
      },
      autoCode: null,// 第三方聚合支付 - 授权code
      tempToken: null,// 第三方聚合支付 - 临时token
      thirdToken: null,// 第三方聚合支付 - 长期token
      dialogVisible: false,
    }
  },
  watch: {
    // 观察父级组件的showFlag，并将showFlag的最新值设置给dialogVisible
    showFlag(newVal) {
      this.dialogVisible = newVal
      if (newVal) this.getOauthCode()
    },
  },
  created() {
    this.basicInfo = JSON.parse(localStorage.getItem('payQuery'))
    this.form.phoneNumber = this.$store.state.userInfo.userAccount//手机号码
    this.thirdToken = this.$store.state.thirdToken || null

  },
  mounted() {
    // 从localStorage恢复倒计时  
    const savedCountdown = localStorage.getItem('countdown');
    if (savedCountdown) {
      this.countdown = parseInt(savedCountdown, 10);
      if (this.countdown > 0) {
        this.startCountdown();
        this.sendMsgDisabled = true
      }
    }
  },
  methods: {
    // 获取第三方授权code
    getOauthCode() {
      this.loading = true;
      this.$axios.post('https://api.eshare168.com/r/sso/oauth/code', {
        appId: 'be442b8bbcdedd88b964dfd92aa1ffa6',
      }).then((res) => {
        if (res.data.msg == 'SUCCESS') {
          this.autoCode = res.data.data
          this.getPayTempToken()
        } else {
          this.$message.error(res.data.msg);
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    // 用第三方的临时code跟后端换取临时token
    getPayTempToken() {
      this.loading = true;
      this.$axios.post(this.$api.payGetToken, {
        code: this.autoCode,
      }).then((res) => {
        if (res.data.code == 100) {
          this.tempToken = res.data.result.token
        } else {
          this.$message.error(res.data.desc);
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    // 发送验证码
    getCaptcha() {
      if (!this.sendMsgDisabled) {
        this.submitLoding = true
        this.$axios.post('https://api.eshare168.com/r/sso/oauth/captchaSms',
          {
            appId: 'be442b8bbcdedd88b964dfd92aa1ffa6',
            data: {
              phoneNumber: this.form.phoneNumber
            }
          },
          {
            headers: {
              'Access-Token': this.tempToken,
              'Content-Type': 'application/json',
            }
          }
        ).then((res) => {
          if (res.data.msg == 'SUCCESS') {
            this.$message.success('短信验证码已发送')
            // 禁用按钮并启动倒计时  
            this.sendMsgDisabled = true;
            // 重置倒计时  
            this.countdown = 30;
            this.startCountdown();
          } else {
            this.$message.error(res.data.msg);
          }
          this.submitLoding = false
          this.loading = false;
        }).catch(() => {
          this.submitLoding = false
          this.loading = false;
        });
      }
    },
    // 验证短信验证码
    verifyCaptcha() {
      this.submitLoding = true
      this.$axios.post('https://api.eshare168.com/r/sso/oauth/login',
        {
          appId: "be442b8bbcdedd88b964dfd92aa1ffa6",
          data: {
            loginType: "phone",
            thirdUserId: '',//验证手机号码绑定
            extData: {
              phoneNumber: this.form.phoneNumber,
              verifyCode: this.form.msgcode
            }
          }
        },
        {
          headers: {
            'Access-Token': this.tempToken,
            'Content-Type': 'application/json',
          }
        }
      ).then((res) => {
        if (res.data.msg == 'SUCCESS') {
          const t_token = res.data.data.token
          this.thirdToken = t_token
          this.$store.commit('setThirdToken', t_token)
          this.bindSupplierPayInfo(t_token)
        } else {
          this.$message.error(res.data.msg);
        }
        this.submitLoding = false
        this.loading = false;
      }).catch(() => {
        this.submitLoding = false
        this.loading = false;
      });
    },
    // 用第三方长期token绑定供应商当前供应商
    bindSupplierPayInfo(token) {
      this.$axios.post(this.$api.payBind, {
        clientToken: token, // 第三方token
        phoneNum: this.form.phoneNumber, // 手机号
        supplierId: this.$store.state.userInfo.supplierId//供应商id
      }).then((res) => {
        if (res.data.code == 100) {
          let pq = this.$store.state.payQuery
          if (pq == null) {
            // let query = {
            //   sourceId: this.basicInfo.sourceId, // 对应平台的主键id
            //   fee: this.basicInfo.fee, // 缴费金额
            //   supplierId: this.$store.state.userInfo.supplierId, //供应商id
            //   sourceType: this.basicInfo.sourceType, // 支付来源：1平台应标资格；2独立标应标资格；3保证金
            // }
            // localStorage.setItem('payQuery', JSON.stringify(query))
            let query = {
              sourceId: this.basicInfo.sourceId, // 对应平台的主键id
              fee: this.basicInfo.fee, // 缴费金额
              supplierId: this.$store.state.userInfo.supplierId, //供应商id
              sourceType: this.basicInfo.sourceType, // 支付来源：1平台应标资格；2独立标应标资格；3保证金
            }
            this.$store.commit('setPayQuery', query)
            setTimeout(() => {
              this.$router.replace({ name: 'twoCodePage', })
            }, 0);
          } else {
            this.$router.replace({ name: 'twoCodePage', })
          }
          this.dialogVisible = false
        } else {
          this.$message.error(res.data.desc);
        }
      });
    },
    startCountdown() {
      // 清除之前的定时器  
      this.clearTimer();
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
          localStorage.setItem('countdown', this.countdown);
        } else {
          // 倒计时结束，重置按钮状态  
          this.clearTimer();
          this.sendMsgDisabled = false;
          localStorage.removeItem('countdown'); // 清除localStorage中的倒计时  
        }
      }, 1000);
    },
    clearTimer() {
      // 清除定时器  
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    // 触发父级组件的closeChildDialog
    onCancel() {
      this.$emit('closeChildDialog')
    },
  },

}

</script>

<style scoped lang='scss'>
.detail {
  // height: 243px;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #E2E2E2;
  overflow: hidden;
  margin-bottom: 20px;

  header {
    height: 45px;
    background: #F4F6F9;
    border-radius: 6px 6px 0px 0px;
    border-bottom: 1px solid #E2E2E2;
    padding-left: 30px;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: bold;
    font-size: 16px;
    color: #1A1A1A;
    line-height: 45px;
  }

  .form {
    padding: 20px 30px 0px 0px;
  }

}

.Btn {
  text-align: center;
}

.captcha_row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 550px;

  .send {
    margin-left: 30px;
  }
}
</style>